export default [
  {
    path: '/admin/dashboard',
    name: 'dashboard-bo',
    meta: { layout: 'admin' },
    component: () => import('../pages/dasboard/index.vue')
  },
  {
    path: '/admin/customers',
    name: 'customers-list',
    meta: { layout: 'admin' },
    component: () => import('../pages/customers/index.vue')
  },
  {
    path: '/admin/customer/:id',
    name: 'customers-edit',
    meta: { layout: 'admin' },
    component: () => import('../pages/customers/edit.vue')
  },
  {
    path: '/admin/categories',
    name: 'categories-list',
    meta: { layout: 'admin' },
    component: () => import('../pages/categories/index.vue')
  },
  {
    path: '/admin/category/:id',
    name: 'categories-view',
    meta: { layout: 'admin' },
    component: () => import('../pages/categories/edit.vue')
  },
  {
    path: '/admin/products',
    name: 'products-list',
    meta: { layout: 'admin' },
    component: () => import('../pages/products/index.vue')
  },
  {
    path: '/admin/product/:id',
    name: 'products-view',
    meta: { layout: 'admin' },
    component: () => import('../pages/products/edit.vue')
  },
  {
    path: '/admin/plataform-terms',
    name: 'plataform-terms-list',
    meta: { layout: 'admin' },
    component: () => import('../pages/plataformTerms/index.vue')
  },
  {
    path: '/admin/plataform-terms/:id',
    name: 'plataform-terms-view',
    meta: { layout: 'admin' },
    component: () => import('../pages/plataformTerms/edit.vue')
  },
  {
    path: '/admin/verticals',
    name: 'verticals-list',
    meta: { layout: 'admin' },
    component: () => import('../pages/verticals/index.vue')
  },
  {
    path: '/admin/verticals/:id',
    name: 'verticals-view',
    meta: { layout: 'admin' },
    component: () => import('../pages/verticals/edit.vue')
  },

  {
    path: '/admin/proposals',
    name: 'proposals-list',
    meta: { layout: 'admin' },
    component: () => import('../pages/proposals/index.vue')
  },
  {
    path: '/admin/proposal/:id',
    name: 'proposal-view',
    meta: { layout: 'admin' },
    component: () => import('../pages/proposals/edit.vue')
  },
  {
    path: '/admin/partners',
    name: 'partners-list',
    meta: { layout: 'admin' },
    component: () => import('../pages/partners/index.vue')
  }
]
